import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, inject, Injectable, isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from '@shared/services';
import { concat, firstValueFrom, interval } from 'rxjs';
import { first } from 'rxjs/operators';

interface UpdateConfig {
  update: 'critical' | 'high' | 'low';
}

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  private readonly notificationService = inject(NotificationService);
  private readonly appRef = inject(ApplicationRef);
  private readonly updates = inject(SwUpdate);
  private readonly http = inject(HttpClient);
  private readonly document = inject(DOCUMENT);

  init(): void {
    // Allow the app to stabilize first, before starting
    // polling for updates every minute.
    const appIsStable$ = this.appRef.isStable.pipe(
      first(isStable => isStable === true)
    );
    const everyMinute$ = interval(60 * 1000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    everyMinuteOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        const config = await this.getUpdateConfig();

        if (updateFound) {
          this.updateAction(config);
        }
      } catch (err) {
        if (!isDevMode()) {
          console.error('Failed to check for updates:', err);
        }
      }
    });
  }

  private getUpdateConfig(): Promise<UpdateConfig> {
    const configFile = './assets/update.config.json';

    return firstValueFrom(this.http.get<UpdateConfig>(configFile));
  }

  private updateAction(config: UpdateConfig): void {
    switch (config.update) {
      case 'critical':
        this.document.location.reload();
        break;

      case 'high':
        {
          const title = 'notifications.updateService.title';
          const message = 'notifications.updateService.message';

          this.notificationService.closeAll();
          this.notificationService.info(message, 0, title, {
            nzClass: 'hide-notification-close-btn',
            nzStyle: {
              background: '#e6f4ff'
            }
          });
        }
        break;

      case 'low':
        // Don't take any action
        break;
    }
  }
}
